.App {
	text-align: center;
}

.banner-text {
	background-color: chocolate;
}

.banner-text > h1 {
	margin: 0;
}

.Navbar {
	font-size: 26px;
	background-color: white;
	position: fixed;
	top: 0;
	z-index: 10;
	border: 1px solid black;
	width: 100%;
	height: 50px;
	/* box-shadow: 10px 10px 10px blue; */
	display: grid;
	grid-template-columns: 3fr 1fr 1fr 1fr;
}

.App-header {
	display: grid;
	grid-template-columns: 1fr 2fr 2fr;
	min-height: 100vh;
}

.Left-header {
	background-color: #b76e79;
	padding: 30px;
	display: grid;
	grid-template-rows: 1fr 2fr;
	color: #ffffff;
}

.Profile-picture {
	align-items: center;
	vertical-align: middle;
	margin: auto;
}

.Profile-picture > img {
	width: 100%;
	height: auto;
	border-radius: 50%;
	filter: grayscale(30%);
}

.Profile-text {
	margin: auto;
}

.Middle-header {
	padding: 30px;
}

.Right-header {
	padding: 30px;
}

@media screen and (max-width: 600px) {
	.App-header {
		grid-template-columns: 1fr;
		min-height: fit-content;
	}

	.Profile-picture > img {
		width: 50%;
	}
}
